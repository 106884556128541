import React from 'react';
import PropTypes from 'prop-types';
// import Link from 'next/link';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
// import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';

import ConnectionSectionWrapper from './connection.style';
// import Connection from '../../../assets/image/hubble/connection.png';
import ConnectionText from '../../../assets/image/hubble/connection_text.svg';
import FloatingImage from "../../../components/FloatingImage";

const ConnectionSection = ({
  row,
  col,
  // title,
  description,
  // button,
  textArea,
  imageArea,
  imagedata,
}) => {
  return (
    <ConnectionSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...row} {...textArea}>
          <Image src={ConnectionText} alt="Greate Display" />
          <Text
              {...description}
              content=" "
            />
          <Text
              {...description}
              content="Using USB Type C DP to connect phone and PC."
            />
          </Box>
          <Box {...row} {...imageArea}>
            {/* <Image /> */}
            {/* <DisplayImage /> */}
            {/* <Img fluid={this.data.placeholderImage.childImageSharp.fluid} /> */}
            {/* <Image src={Connection} alt="Greate Display" /> */}
            <FloatingImage
            imageMobile={imagedata.connMobileImage.childImageSharp.fluid}
            imageDesktop={imagedata.connDesktopImage.childImageSharp.fluid}
            />
          </Box>
        </Box>
      </Container>
    </ConnectionSectionWrapper>
  );
};

ConnectionSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

ConnectionSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
    justifyContent: 'center',
    // flexDirection: 'column',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    flexDirection: 'column',
    width: ['80%', '80%', '80%', '90%', '100%'],
    // mt: ['-160px', '-160px', '-160px', '-180px', '-200px'],
  },
  imageArea: {
    width: ['80%', '80%', '80%', '90%', '100%'],
    mb: ['40px', '40px', '40px', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#000',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    textAlign: ['center', 'center', 'left', 'left', 'left'],
    fontSize: '16px',
    color: '#000',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default ConnectionSection;