import styled from '@emotion/styled';
//import BgImage from '../../../assets/image/hubble/pixel.png';

// background-color: #fff;
// background-image: url(${BgImage});
// background-repeat: no-repeat;
// background-position:  top;
// background-size: 100vw auto;

const VisionSectionWrapper = styled.section`
  padding: 120px 0;

  #media-pixel::before,
  #media-pixel::after {
  /*width: 100%;
  min-height: 100vh;*/
  /* You should set a background-size as the default value is "cover"! */
  background-size: 100vw auto;
  background-position:  top;
  /* So we won't have the default "lightgray" background-color. */
  background-color: transparent;
  }

  .ui-square-nerd {
    width: 100%;
    height: 0;
    padding-bottom: 35%,55%;
    background: blue;
  }
  
  .ui-square {
    margin-top: 30px;
    width: 20vh;
    height: 20vh;
    background: green;
  }
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 990px) {
    padding: 100px 0;
    .desktop {
      display: block !important;
      max-width: 60% !important;
    }
    .mobile {
      display: none !important;
      margin: auto;
    }
  }
  @media (max-width: 767px) {
    padding: 80px 0;
    .desktop {
      display: none !important;
    }
    .mobile {
      display: block !important;
      max-width: 100%;
      margin: auto;
    }
    #media-pixel::before,
    #media-pixel::after {
      background-position:  bottom;
    }
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export default VisionSectionWrapper;

export const StyledInnerWrapper = styled.div`
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;