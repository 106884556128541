import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasThreeTheme } from 'common/src/theme/saasThree';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'common/src/containers/hubble/saasThree.style';

import BannerSection from 'common/src/containers/hubble/Banner';
import Navbar from 'common/src/containers/hubble/Navbar';
// import ServiceSection from 'common/src/containers/hubble/Service';
import DisplaySection from 'common/src/containers/hubble/Display';
import SoundSection from 'common/src/containers/hubble/Sound';
import ConnectionSection from 'common/src/containers/hubble/Connection';
import VisionSection from 'common/src/containers/hubble/Vision';
import PixelSection from 'common/src/containers/hubble/Pixel';
// import PricingSection from 'common/src/containers/hubble/Pricing';
// import PartnerSection from 'common/src/containers/hubble/Partner';
import TrialSection from 'common/src/containers/hubble/Trial';
// import FeatureSection from 'common/src/containers/hubble/Feature';
// import UpdateScreen from 'common/src/containers/hubble/UpdateScreen';
// import TestimonialSection from 'common/src/containers/hubble/Testimonial';
import Newsletter from 'common/src/containers/gojoylab/Newsletter';
import Footer from 'common/src/containers/gojoylab/Footer';
import '@redq/reuse-modal/es/index.css';
import SEO from '../components/seo';
// import FloatingImage from 'common/src/components/FloatingImage';

export default ( { data } ) => {
  return (
    <ThemeProvider theme={saasThreeTheme}>
      <Fragment>
        <SEO title="GojoyLab | AR Hubble" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <DisplaySection imagedata = {data}/>
          <SoundSection imagedata = {data}/>
          <ConnectionSection imagedata = {data}/>
          <VisionSection imagedata = {data}/>
          <PixelSection imagedata = {data}/>
          {/* <ServiceSection />
          <UpdateScreen />
          <FeatureSection />
          <PartnerSection />
          <PricingSection />
          <TestimonialSection /> */}
          <TrialSection />
          <Newsletter />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

// import Layout from "../components/layout";
// // import VisionImage from 'common/src/containers/hubble/Vision/visionImage';
// import Image from "../components/image";

// export default () => {
//   return (
//     <Layout>
//       <VisionSection />
//     </Layout>
//   )
// };
export const query = graphql`
  query {
    displayMobileImage: file(relativePath: { eq: "hubble/display.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    displayDesktopImage: file(
      relativePath: { eq: "hubble/display.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 968, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }    
    soundMobileImage: file(relativePath: { eq: "hubble/sound.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    soundDesktopImage: file(
      relativePath: { eq: "hubble/sound.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 850, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    connMobileImage: file(relativePath: { eq: "hubble/connection.png" }) {
      childImageSharp {
        fluid(maxWidth: 680, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    connDesktopImage: file(
      relativePath: { eq: "hubble/connection.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1170, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    visionMobileImage: file(relativePath: { eq: "hubble/vision.png" }) {
      childImageSharp {
        fluid(maxWidth: 680, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    visionDesktopImage: file(
      relativePath: { eq: "hubble/vision.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pixelMobileImage: file(relativePath: { eq: "hubble/pixel.png" }) {
      childImageSharp {
        fluid(maxWidth: 680, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pixelDesktopImage: file(
      relativePath: { eq: "hubble/pixel.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`