import styled from 'styled-components';
// import BgImage from '../../../assets/image/gojoylab/map.png';

const SoundSectionWrapper = styled.section`
  padding: 120px 0;
  background-color: #fff;
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 990px) {
    padding: 100px 0;
    .desktop {
      display: block !important;
    }
    .mobile {
      display: none !important;
      margin: auto;
    }
  }
  @media (max-width: 767px) {
    padding: 80px 0;
    .desktop {
      display: none !important;
    }
    .mobile {
      display: block !important;
      margin: auto;
    }
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export default SoundSectionWrapper;
