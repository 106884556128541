import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from 'prop-types';
// import Link from 'next/link';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
// import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from '../../../components/UI/Container';

import DisplaySectionWrapper from './display.style';
import Display from '../../../assets/image/hubble/display.png';
import DisplayText from '../../../assets/image/hubble/GREAT_DISPLAY_TEXT.svg';
import DisplayMobileText from '../../../assets/image/hubble/GREAT_DISPLAY_MOBILE_TEXT.svg';
import FloatingImage from "../../../components/FloatingImage";

const DisplaySection = ({
  row,
  col,
  // title,
  description,
  // button,
  textArea,
  imageArea,
  imagedata,
}) => {
  return (
    <DisplaySectionWrapper id="feature_section">
      <Container>
        <Box {...row}>
          <Box {...col} {...textArea}>
            {/* <Heading
              {...title}
              content="Great"
            /> */}
            {/* <Img fluid={sources} /> */}
            <img className="desktop" src={DisplayText} alt="Greate Display" />
            <img className="mobile" src={DisplayMobileText} alt="Greate Display" />
            <Text
              {...description}
              content=""
            />
            <Text
              {...description}
              content="AR/3D/2D DISPLAY"
            />
          </Box>
          <Box {...col} {...imageArea}>
            {/* <Image /> */}
            {/* <DisplayImage /> */}
            {/* <Img fluid={this.data.placeholderImage.childImageSharp.fluid} /> */}
            {/* <Image src={Display} alt="Greate Display" /> */}
            <FloatingImage
            imageMobile={imagedata.displayMobileImage.childImageSharp.fluid}
            imageDesktop={imagedata.displayDesktopImage.childImageSharp.fluid}
            />
          </Box>
        </Box>
      </Container>
    </DisplaySectionWrapper>
  );
};

DisplaySection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
  data: PropTypes.object,
};

DisplaySection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'top',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '43%', '38%', '30%'],
  },
  imageArea: {
    width: ['100%', '100%', '57%', '62%', '70%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#000',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.25',
  },
  description: {
    textAlign: ['center', 'center', 'left', 'left', 'left'],
    fontSize: '16px',
    color: '#000',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default DisplaySection;